import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import Box from "./Box";

function App() {
  const [pageVisits, setPageVisits] = useState([0]);
  const [activeScene, setActiveScene] = useState(0);
  const scenes = [
    {
      title: `C${pageVisits}ming Soon`,
      description: "please check back later.",
    },
    {
      title: "Creative Thinking",
      description: `Is an important skill for any developer, but especially a design focused developer. \n It entails the ability to think outside of the box.`,
    },
    {
      title: "Averting Expectations",
      description:
        "Specialize me 2 - Ontwerpend Technoloog. Vormvrije Signatuur. 2022-2023",
    },
  ];

  // add 1 page visit every visit
  useEffect(() => {
    let pageVisits = JSON.parse(localStorage.getItem("pageVisits"));
    if (pageVisits) {
      pageVisits = parseInt(pageVisits);
      setPageVisits(pageVisits + 1);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("pageVisits", JSON.stringify(pageVisits));
    if (pageVisits >= 5) {
      setActiveScene(activeScene + 1);
    }
  }, [pageVisits]);

  const clickHandler = (e) => {
    console.log(e.target.className);
    switch (activeScene) {
      case 1:
        if (e.target.className != "box") {
          setActiveScene(activeScene + 1);
        }
        break;
    }
  };

  return (
    <div className="App" onClick={clickHandler}>
      <p></p>
      <Box {...scenes[activeScene]} />

      <p>
        page loads {pageVisits}, active scene: {activeScene}
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </div>
  );
}

export default App;
