import "./Box.css";

export default function Box({ title, description }) {
  return (
    <div className="box">
      <h1 className="box">{title.toUpperCase()}</h1>
      <p className="box">{description}</p>
    </div>
  );
}
